@layer modules, ui, base;
@layer base {
  .NewCv_setCvAsDefault__NFqtA {
  margin-top: var(--sk-space-8);
}

.NewCv_setCvAsDefaultTooltip__6GGp8 {
  margin-left: var(--sk-space-8);
  vertical-align: bottom;
}

}
